const visibleClass = "visible";
const invisibleClass = "invisible";
const interval = 20_000; //ms
const slides = document.querySelector(".slides")!.children;

let numSlides = slides.length;
let currentSlide = 0;

if (numSlides > 0)
  setInterval(() => {
    currentSlide = (currentSlide + 1) % numSlides;
    updateCurrentSlide(currentSlide, slides);
  }, interval);

// run once to initialize state
updateCurrentSlide(0, slides);

//
function updateCurrentSlide(current: number, slides: HTMLCollection) {
  for (let i = 0; i < slides.length; ++i) {
    if (i === current) {
      slides.item(i).classList.remove(invisibleClass);
      slides.item(i).classList.add(visibleClass);
    } else {
      slides.item(i).classList.remove(visibleClass);
      slides.item(i).classList.add(invisibleClass);
    }
  }
}
